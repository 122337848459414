import {zodResolver} from '@hookform/resolvers/zod'
import {FormItem} from '@myadbox/gatsby-theme-nebula'
import {SubmitHandler, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {z} from 'zod'
import useSocialConnections from '../../hooks/useSocialConnections'
import withTranslationProvider from '../../locales/withTranslationProvider'
import SocialActiveCard from './SocialActiveCard'

const validationSchema = z.object({
  platforms: z.array(z.string()).optional(),
})
type FormData = z.infer<typeof validationSchema>

const SocialActiveConnections: React.FC = () => {
  const {register, handleSubmit} = useForm<FormData>({
    resolver: zodResolver(validationSchema),
  })
  const {t} = useTranslation(`socialActiveConnections`)

  const {
    integratedPlatforms,
    isSubmitting,
    handleDisconnect,
    fetchConnectedIntegrationsResults,
    fetchIntegrationsResults,
  } = useSocialConnections()

  if (
    fetchConnectedIntegrationsResults.loading ||
    fetchIntegrationsResults.loading
  ) {
    return <p>{t(`loadingMessage`)}</p>
  }

  if (
    fetchConnectedIntegrationsResults.error ||
    fetchIntegrationsResults.error
  ) {
    return <p>{t(`errors.integrations`)}</p>
  }

  const hasConnectedIntegrations = integratedPlatforms.length > 0

  const onSubmit: SubmitHandler<FormData> = async data => {
    for (const platform of data.platforms || []) {
      const integration = integratedPlatforms.find(
        p => p.name.toLowerCase() === platform
      )
      if (integration) {
        await handleDisconnect(integration.integrationId)
      }
    }
  }

  const camelize = (str: string) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
      if (+match === 0) return ``
      return index === 0 ? match.toLowerCase() : match.toUpperCase()
    })
  }

  return (
    hasConnectedIntegrations && (
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormItem
          label={
            <FormItem.Label tag="span">
              {t(`socialIntegrationsLbl`)}
            </FormItem.Label>
          }
        >
          <div className="flex flex-col gap-3">
            {integratedPlatforms.map((platform, index) => (
              <div key={platform.integrationId}>
                <SocialActiveCard
                  platform={camelize(platform.name.toLowerCase())}
                  isConnected={true}
                  integrationId={platform.integrationId}
                  isSubmitting={isSubmitting ?? false}
                  handleDisconnect={handleDisconnect}
                />
                <input
                  type="hidden"
                  {...register(`platforms.${index}`)}
                  value={platform.name.toLowerCase()}
                />
              </div>
            ))}
          </div>
        </FormItem>
      </form>
    )
  )
}

export default withTranslationProvider(SocialActiveConnections)
