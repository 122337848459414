import {CopyDetail, Modal, TextArea} from '@myadbox/gatsby-theme-nebula'
import {
  ActionGroup,
  Button,
  FacebookIcon,
  GoogleAdsIcon,
  InstagramIcon,
  Text,
} from '@myadbox/stellar-ui'
import {useTranslation} from 'react-i18next'
import withTranslationProvider from '../../../locales/withTranslationProvider'
import {IntegrationType, RefetchFunction} from '../types'
import {useSocialsIntegrations, useSocialsModalStore} from '../hooks'
import {
  Asset,
  ExchangeTokenInput,
  useAccount,
  useExchangeToken,
  useProfiles,
} from '@myadbox/nebula-service-api'
import {useClientMetaAuth} from '../../../hooks'
import {paramsToObject, redirectSource} from '../../../utils/helpers'
import {CheckIcon} from '@heroicons/react/24/outline'
import {useEffect, useState} from 'react'

const appId = process.env.GATSBY_META_CLIENT_ID || ``
const apiBaseUrl = process.env.GATSBY_SERVICES_API_GATEWAY || ``

export const IntegrationIcon = ({type}: {type: IntegrationType}) => {
  switch (type) {
    case `Facebook`:
      return <FacebookIcon size={34} />
    case `Instagram`:
      return <InstagramIcon size={34} />
    case `GoogleAds`:
      return <GoogleAdsIcon size={34} />
    default:
      return <div></div>
  }
}

export const handleReturnMessageBuilder = ({
  selectedExportIntegrationId,
  activeIntegrationType,
  exchangeToken,
  refetch,
}: {
  selectedExportIntegrationId: string
  activeIntegrationType: IntegrationType
  exchangeToken: (input: ExchangeTokenInput) => void
  refetch: RefetchFunction
}) => {
  return async (event: {
    origin: string
    data: {payload: string; source: string}
  }) => {
    if (
      event.origin !== window.location.origin &&
      event?.data?.source !== redirectSource
    ) {
      return
    }
    const params = paramsToObject(event.data.payload)
    if (
      params &&
      selectedExportIntegrationId &&
      activeIntegrationType &&
      `access_token` in params
    ) {
      exchangeToken({
        integrationId: selectedExportIntegrationId,
        type: String(activeIntegrationType).toLocaleUpperCase(),
        temporaryToken: params.access_token,
      })
      await refetch()
    }
  }
}

export const SocialsEmbed = ({assets}: {assets: Asset[]}) => {
  const {t} = useTranslation(`socialsIntegrations`)
  const {account} = useAccount()
  const {profile} = useProfiles()

  const [embedCode, setEmbedCode] = useState(``)

  useEffect(() => {
    const {id, name, versions} = assets[0]
    const {userId} = profile

    const payload = {
      id: id,
      uid: userId,
      acc: account?.id,
    }

    const base64Payload = window.btoa(JSON.stringify(payload))

    const imgSrc = `${process.env.GATSBY_EMBED_SUBDOMAIN}/image/upload/${versions[0].cloudinaryId}?p=${base64Payload}`
    const embedTag = `<img alt="${name}" src="${imgSrc}" />`
    setEmbedCode(embedTag)
  }, [account, assets, profile])

  return (
    <>
      <Text>{t(`embed.code`)}</Text>
      <TextArea
        id="embedCode"
        name="embedCode"
        label={t(`embed.code`)}
        labelHidden
        value={embedCode}
        rows={7}
        disabled
        noResize
      />
      <div
        className="
          transition-property-default
          active:bg-ui-50
          active:text-ui-800
          hover:border-ui-500
          hover:text-ui-700
          text-ui-600
          dark:hover:border-ui-300
          relative
          inline-flex
          items-center
          justify-center
            whitespace-nowrap
            rounded
            border
            px-4
            py-2
          text-left
          text-sm
          font-medium
          leading-5
          duration-75
          ease-in-out
          focus:border-lime-300
        "
      >
        <CopyDetail title={t(`embed.copy`)} copyText={embedCode}>
          {t(`embed.copy`)}
        </CopyDetail>
      </div>
    </>
  )
}

const SocialsIntegrationsConnect = ({assets}: {assets: Asset[]}) => {
  const {t} = useTranslation(`socialsIntegrations`)
  const {exchangeToken, exchangeTokenResponse} = useExchangeToken()
  const {data, loading, error} = exchangeTokenResponse

  const integrationType = useSocialsModalStore(state => state.integrationType)
  const isGoogleAds = integrationType === `GoogleAds`
  const isEmbed = integrationType === `Embed`
  const reset = useSocialsModalStore(state => state.reset)

  const {refetch, selectedIntegration, connectedIntegration} =
    useSocialsIntegrations()

  const handleReturnMessage = handleReturnMessageBuilder({
    selectedExportIntegrationId: selectedIntegration?.id ?? ``,
    activeIntegrationType: integrationType,
    exchangeToken,
    refetch,
  })

  const {handleMetaLogin} = useClientMetaAuth(handleReturnMessage, appId)

  const handleConnect = () => {
    // istanbul ignore next
    handleMetaLogin(integrationType, apiBaseUrl)
  }

  const expiryDate = connectedIntegration
    ? new Date(connectedIntegration.user.token.expiresAt).getTime()
    : Date.now() - 1000
  const hasExpired = expiryDate <= Date.now() ? `getToken` : `resetToken`

  return (
    <>
      <Modal.Body>
        <div className="flex flex-col gap-4">
          {isEmbed ? (
            <SocialsEmbed assets={assets} />
          ) : (
            <>
              <IntegrationIcon type={integrationType} />
              <Text>
                {t(`connect.${hasExpired}.body`, {
                  integration: isGoogleAds ? `Google Ads` : integrationType,
                })}
              </Text>
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ActionGroup>
          {error && <Text>{t(`connect.error`)}</Text>}
          {!loading && data ? (
            <div
              className={`
                    flex
                    items-center
                    justify-center
                    gap-1
                    text-green-600
                  `}
            >
              <CheckIcon width={24} height={24} />
              <Text>{t`connect.connected`}</Text>
            </div>
          ) : (
            <>
              <Button variant="secondary" onClick={reset}>
                {t(isEmbed ? `embed.close` : `connect.cancel`)}
              </Button>
              {!isEmbed && (
                <Button
                  variant="primary"
                  onClick={handleConnect}
                  disabled={loading}
                  loading={loading}
                  loadingText={t`connect.updating`}
                >
                  {t(`connect.${hasExpired}.connect`, {
                    integration: isGoogleAds ? `Google Ads` : integrationType,
                  })}
                </Button>
              )}
            </>
          )}
        </ActionGroup>
      </Modal.Footer>
    </>
  )
}

export default withTranslationProvider(SocialsIntegrationsConnect)
