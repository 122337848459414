import withTranslationProvider from '../../../locales/withTranslationProvider'
import {useSocialsModalStore, useSocialsIntegrations} from '../hooks'
import SocialsIntegrationsConnect from '../SocialsIntegrationsConnect'
import SocialsIntegrationsForm from '../SocialsIntegrationsForm'
import {Modal} from '@myadbox/gatsby-theme-nebula'
import {useTranslation} from 'react-i18next'
import {useEffect} from 'react'
import {Asset} from '@myadbox/nebula-service-api'

const SocialsIntegrationsModal = ({assets}: {assets: Asset[]}) => {
  const contentState = useSocialsModalStore(state => state.contentState)
  const setContentState = useSocialsModalStore(state => state.setContentState)
  const isModalOpen = useSocialsModalStore(state => state.isModalOpen)
  const reset = useSocialsModalStore(state => state.reset)
  const integrationType = useSocialsModalStore(state => state.integrationType)

  const {connectedIntegration} = useSocialsIntegrations()

  const {t} = useTranslation(`socialsIntegrations`)

  useEffect(() => {
    if (connectedIntegration) setContentState(`connected`)
    else setContentState(`connect`)
  }, [connectedIntegration, setContentState])

  const renderModal = () => {
    if (contentState === `connect`)
      return <SocialsIntegrationsConnect assets={assets} />
    if (contentState === `connected`)
      return <SocialsIntegrationsForm assets={assets} />
  }
  return (
    <Modal
      controls={{isOpen: isModalOpen, onClose: reset}}
      maxWidth="md"
      id="default-integrations-modal"
    >
      <Modal.Header>{t(`header.${integrationType}`)}</Modal.Header>
      {renderModal()}
    </Modal>
  )
}

export default withTranslationProvider(SocialsIntegrationsModal)
