import {FacebookIcon, GoogleAdsIcon, InstagramIcon} from '@myadbox/stellar-ui'
import withTranslationProvider from '../../../locales/withTranslationProvider'
import {ReactNode} from 'react'
import {IntegrationType} from '../types'
import {useSocialsModalStore} from '../hooks'
import SocialsIntegrationsTrigger from '../SocialsIntegrationsTrigger'
import {useTranslation} from 'react-i18next'
import {useFeatureFlags} from '@myadbox/gatsby-theme-nebula/hooks'
import {CodeBracketIcon} from '@heroicons/react/20/solid'

interface IntegrationItem {
  name: IntegrationType
  icon: ReactNode
}

const SocialsIntegrationsList = () => {
  const {t} = useTranslation(`socialsIntegrations`)
  const {
    featureFlags: {showGoogleAdsConnection, showEmbedCopy},
  } = useFeatureFlags()

  const integrationList: IntegrationItem[] = [
    {
      name: `Facebook`,
      icon: <FacebookIcon size={20} />,
    },
    {
      name: `Instagram`,
      icon: <InstagramIcon size={20} />,
    },
    {
      name: `GoogleAds`,
      icon: <GoogleAdsIcon size={20} />,
    },
    {
      name: `Embed`,
      icon: (
        <div className="size-5">
          <CodeBracketIcon />
        </div>
      ),
    },
  ].filter(
    ({name}) =>
      (!showEmbedCopy && name !== `Embed`) ||
      (!showGoogleAdsConnection && name !== `GoogleAds`)
  ) as IntegrationItem[]

  const setModalOpen = useSocialsModalStore(state => state.setModalOpen)
  const setIntegrationType = useSocialsModalStore(
    state => state.setIntegrationType
  )

  return (
    <>
      {integrationList.map(({name, icon}) => (
        <SocialsIntegrationsTrigger
          key={name}
          text={t(`list.${name}`)}
          renderIcon={icon}
          handleSelect={() => {
            setModalOpen()
            setIntegrationType(name)
          }}
        />
      ))}
    </>
  )
}

export default withTranslationProvider(SocialsIntegrationsList)
