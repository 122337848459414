import {Modal, RadioButton, TextArea} from '@myadbox/gatsby-theme-nebula'
import {Asset, ExportSocialInput} from '@myadbox/nebula-service-api'
import {ActionGroup, Button, FormItem, Text} from '@myadbox/stellar-ui'
import {Form, Formik} from 'formik'
import {useTranslation} from 'react-i18next'
import {useSocialsIntegrations, useSocialsModalStore} from '../hooks'
import {SocialsIntegrationFormValues} from '../types'
import withTranslationProvider from '../../../locales/withTranslationProvider'
import {CheckIcon} from '@heroicons/react/24/outline'
import {useEffect} from 'react'

const SocialsIntegrationsForm = ({assets}: {assets: Asset[]}) => {
  const {t} = useTranslation(`socialsIntegrations`)
  const integrationType = useSocialsModalStore(state => state.integrationType)
  const reset = useSocialsModalStore(state => state.reset)
  const {connectedIntegration, exportSocial, exportResponse} =
    useSocialsIntegrations()
  const {data, loading, error} = exportResponse

  const isGoogleAds = integrationType === `GoogleAds`

  const initialValues: SocialsIntegrationFormValues = {
    page: connectedIntegration?.apps[0]?.id ?? null,
    asset: assets[0],
    message: ``,
    user: connectedIntegration?.user?.name ?? `Sesimi User`,
  }

  useEffect(() => {
    if (data?.createImagePost && !error) {
      setTimeout(reset, 1500)
    }
  }, [data, error, reset])

  const handleSubmit = async (values: SocialsIntegrationFormValues) => {
    const {asset, message, page} = values
    const payloadBody = {
      id: page,
      integrationId: connectedIntegration?.integrationId,
      assetId: asset?.id,
      type: integrationType?.toString().toLocaleUpperCase(),
    }
    const payload = isGoogleAds
      ? ({
          ...payloadBody,
        } as ExportSocialInput)
      : ({
          ...payloadBody,
          message,
        } as ExportSocialInput)
    exportSocial(payload)
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({values, setFieldValue, isValid}) => (
        <Form className="flex flex-col">
          <Modal.Body>
            <div>
              <Text variant="bodySmall" color="textSecondary">
                {t(`form.loginAs`)}
              </Text>
              <Text>{values?.user}</Text>
            </div>
            <FormItem
              label={
                <FormItem.Label>
                  {t(`form.pages.${isGoogleAds}`)}
                </FormItem.Label>
              }
            >
              <div>
                {connectedIntegration?.apps.map(app => (
                  <label
                    key={app.id}
                    className="flex flex-row items-center gap-2 py-1"
                  >
                    <RadioButton
                      name="page"
                      id={app.id}
                      value={app.name}
                      checked={values.page === app.id}
                      onChange={() => setFieldValue(`page`, app.id)}
                      required
                    />
                    <Text>
                      {app.name}
                      {isGoogleAds && ` (${app.id})`}
                    </Text>
                  </label>
                ))}
              </div>
            </FormItem>
            {isGoogleAds ? (
              <div />
            ) : (
              <TextArea.Formik
                placeholder={`Enter message here`}
                id="message"
                name="message"
                label={t(`form.enterMessage.${isGoogleAds}`)}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <ActionGroup>
              {error && <Text>{error.message}</Text>}
              {!loading && data?.createImagePost ? (
                <div
                  className={`
                    flex
                    items-center
                    justify-center
                    gap-1
                    text-green-600
                  `}
                >
                  <CheckIcon width={24} height={24} />
                  <Text>{data.createImagePost.message}</Text>
                </div>
              ) : (
                <>
                  <Button variant="secondary" type="button" onClick={reset}>
                    {t(`form.cancel`)}
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    id={`socials-integrations-submit`}
                    disabled={!isValid || loading}
                    loading={loading}
                    loadingText={t`form.updating`}
                  >
                    {t(`form.share.${isGoogleAds}`)}
                  </Button>
                </>
              )}
            </ActionGroup>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  )
}

export default withTranslationProvider(SocialsIntegrationsForm)
