import {create} from 'zustand'
import {ContentState, IntegrationType, SocialsModalStore} from '../types'

export const useSocialsModalStore = create<SocialsModalStore>()(set => ({
  isModalOpen: false,
  contentState: `connect`,
  integrationType: `Facebook`,
  setContentState: (content: ContentState) => set({contentState: content}),
  setIntegrationType: (social: IntegrationType) =>
    set({integrationType: social}),
  setModalOpen: () => set(state => ({isModalOpen: !state.isModalOpen})),
  reset: () =>
    set({
      isModalOpen: false,
      contentState: `connect` as ContentState,
      integrationType: `Facebook`,
    }),
}))
